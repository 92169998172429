
import { Component, Vue } from "vue-property-decorator";
import { format } from "date-fns";

@Component({
  props: {
    value: {
      type: String,
      required: true
    }
  }
})
export default class TimestampTdContent extends Vue {
  get time() {
    const date = new Date(this.value);
    return {
      date: format(date, "dd.MM.yyyy"),
      time: format(date, "HH:mm")
    };
  }
}
