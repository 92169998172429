export function isDate(str: string) {
  if (
    typeof str !== 'string' ||
    !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{2,8}(Z|[+-]\d{2}:\d{2})$/.test(
      str
    )
  ) {
    return false;
  }
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime());
}
